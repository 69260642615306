<template>
  <div>
    <h1> Account </h1>
  </div>
</template>
<script>
  export default {
    data() {
      return {

      }
    }
  }
</script>
